import {
  addMonths,
  isAfter,
  isBefore,
  isEqual,
  isValid,
  startOfMonth,
  subDays,
} from 'date-fns'

// copied from Fleet-customer
// TODO find a way to reuse from there

export type FiscalQuarter = {
  start: Date
  end: Date
  fiscalYear: number
  fiscalQuarter: number
}

export function getFiscalQuarter(
  quarter: number,
  year: number,
  fiscalYearStartMonth: number // 1-indexed, i.e. 1=Jan, 2=Feb, ...
): FiscalQuarter {
  if (quarter < 1 || quarter > 4) {
    throw new Error('quarter cannot be outside 1-4 range: ' + quarter)
  }
  checkFiscalYearStartMonth(fiscalYearStartMonth)

  const startOfFirstQuarterOfYear = startOfMonth(
    new Date(year, fiscalYearStartMonth - 1, 1)
  )

  return {
    start: addMonths(startOfFirstQuarterOfYear, (quarter - 1) * 3),
    end: subDays(
      addMonths(startOfFirstQuarterOfYear, (quarter - 1) * 3 + 3),
      1
    ),
    fiscalYear: year,
    fiscalQuarter: quarter,
  }
}

export function getFiscalQuarterByDate(
  date: Date,
  fiscalYearStartMonth: number // 1-indexed, e.g. 1=Jan, 2=Feb, ...
): FiscalQuarter {
  if (!isValid(date)) {
    throw new Error('Invalid date')
  }
  checkFiscalYearStartMonth(fiscalYearStartMonth)

  let q = 1
  let y = date.getFullYear() - 1
  let quarter = getFiscalQuarter(q, y, fiscalYearStartMonth)
  while (
    !(isSameOrAfter(date, quarter.start) && isSameOrBefore(date, quarter.end))
  ) {
    q++
    if (q > 4) {
      y++
      q = 1
    }
    quarter = getFiscalQuarter(q, y, fiscalYearStartMonth)
  }

  return quarter
}

export function getFormattedFiscalQuarterName(
  date: Date,
  fiscalYearStartMonth: number
) {
  const q = getFiscalQuarterByDate(date, fiscalYearStartMonth)
  return `Q${q.fiscalQuarter} ${getFormattedYear(q, fiscalYearStartMonth)}`
}

function getFormattedYear(
  quarter: FiscalQuarter,
  fiscalYearStartMonth: number
) {
  return fiscalYearStartMonth !== 1
    ? `${quarter.fiscalYear.toString()}/${(
        quarter.fiscalYear +
        1 -
        2000
      ).toString()}`
    : quarter.fiscalYear.toString()
}

function checkFiscalYearStartMonth(m: number) {
  if (!m || m < 1 || m > 12) {
    throw new Error('fiscalYearStartMonth cannot be outside 1-12 range: ' + m)
  }
}

function isSameOrAfter(date: Date, dateToCompare: Date) {
  return isAfter(date, dateToCompare) || isEqual(date, dateToCompare)
}

function isSameOrBefore(date: Date, dateToCompare: Date) {
  return isBefore(date, dateToCompare) || isEqual(date, dateToCompare)
}
