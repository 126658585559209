import { AuthorizedFleetAccount } from '../../_core/models/customer.models'
import { sortBy } from 'lodash'
import { SubAccount } from '@boosterfuels/types/types-ts/embedded'

export interface FleetAccountOption {
  _id: string
  name: string
  subAccounts?: { subAccountId: string }[]
}
export type SubAccountOption = Omit<FleetAccountOption, 'subAccounts'>

export function getAccountSelectOptions(
  hasSubaccountRole: boolean,
  selectedFleetCompanyId: string,
  selectedFleetAccountId: string,
  authorizedFleetAccounts: Partial<AuthorizedFleetAccount>[],
  fleetCompanySubAccounts: Partial<SubAccount>[]
): {
  fleetAccountOptions: FleetAccountOption[]
  subAccountOptions: SubAccountOption[]
} {
  let fleetAccountOptions = []
  let subAccountOptions = []

  if (!hasSubaccountRole) {
    if (selectedFleetAccountId === 'ALL') {
      const filteredFleetAccounts = authorizedFleetAccounts.filter(
        (fleetAccount) => fleetAccount.fleetCompanyId === selectedFleetCompanyId
      )
      fleetAccountOptions = filteredFleetAccounts.map((fleetAccount) => ({
        _id: fleetAccount._id,
        name: fleetAccount.name,
        subAccounts: fleetAccount.subAccounts,
      }))
      subAccountOptions = filterSubAccountsByFleetAccounts(
        fleetCompanySubAccounts,
        filteredFleetAccounts
      )
    } else {
      const fleetAccount = authorizedFleetAccounts.find(
        (fleetAccount) => fleetAccount._id === selectedFleetAccountId
      )
      subAccountOptions = filterSubAccountsByFleetAccounts(
        fleetCompanySubAccounts,
        [fleetAccount]
      )
    }
  }

  return {
    fleetAccountOptions: sortBy(fleetAccountOptions, 'name'),
    subAccountOptions: sortBy(subAccountOptions, 'name'),
  }
}

function filterSubAccountsByFleetAccounts(
  subAccounts: Partial<SubAccount>[],
  fleetAccounts: Partial<AuthorizedFleetAccount>[]
): Partial<SubAccount>[] {
  // Reduce fleetAccounts to subAccountIds array while dropping duplicates
  const subAccountIds: string[] = fleetAccounts.reduce((acc, fleetAccount) => {
    fleetAccount.subAccounts.forEach(({ subAccountId }) => {
      if (!acc.includes(subAccountId)) {
        acc.push(subAccountId)
      }
    })
    return acc
  }, [])

  // Map each subAccountId to a matching subAccount
  const filteredSubAccounts = subAccountIds
    .map((subAccountId) =>
      subAccounts.find((subAccount) => subAccount._id === subAccountId)
    )
    .filter((subAccountId) => !!subAccountId)

  return filteredSubAccounts
}
