import { ENERGY_TYPE_GROUPS } from '../../_core/models/energy-types.models'
import {
  FleetAggregationsMonthly,
  FleetAggregationsWeekly,
} from '@boosterfuels/types/types-ts'

export function getServicedEnergyTypesFromAggregations(
  aggregations: (FleetAggregationsWeekly | FleetAggregationsMonthly)[]
): string[] {
  let servicedEnergyTypeGroups = aggregations.reduce((acc, curr) => {
    curr.gallonsDispensed.forEach((item) => acc.push(item.energyTypeGroup))
    return acc
  }, [])

  // ensures that servicedEnergyTypeGroups are in the standard order
  servicedEnergyTypeGroups = ENERGY_TYPE_GROUPS.filter((group) =>
    servicedEnergyTypeGroups.includes(group)
  )

  return servicedEnergyTypeGroups
}
