export function includesFleetAccountFleetRole(role: string) {
  return ['FLEET_ACCOUNT_ADMIN', 'FLEET_ACCOUNT_MEMBER'].includes(role)
}

export function includesFleetCompanyFleetRole(role: string) {
  return ['FLEET_COMPANY_ADMIN', 'FLEET_COMPANY_MEMBER'].includes(role)
}

export function includesFleetSubAccountFleetRole(role: string) {
  return 'FLEET_SUB_ACCOUNT' === role
}
