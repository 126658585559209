import { AuthorizedFleetAccount } from '../../_core/models/customer.models'

export function createFleetAccountsDictionary(
  authorizedFleetAccounts: AuthorizedFleetAccount[]
) {
  return authorizedFleetAccounts.reduce<{
    [key: string]: AuthorizedFleetAccount
  }>((fleetAccountsDictionary, fleetAccount) => {
    fleetAccountsDictionary[fleetAccount._id] = fleetAccount
    return fleetAccountsDictionary
  }, {})
}
