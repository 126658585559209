import { createSelector } from '@ngrx/store'
import { get } from 'lodash'
import { FleetCompany } from '@boosterfuels/types/types-ts'
import { AppStateWithUser, State } from './user.reducer'
import { ServiceLocation } from '../../models/customer.models'
import {
  createFleetAccountsDictionary,
  getAccountSelectOptions,
} from '../../../_shared/helpers'
import { includesFleetCompanyFleetRole } from '@fleet-customer/shared/fleet-roles'

const selectUserState = (state: AppStateWithUser) => state.user

export const selectProducts = createSelector(
  selectUserState,
  (state: State) => state.products
)

export const selectIsFleetCustomerLoading = createSelector(
  selectUserState,
  (state: State) => state.isFleetCustomerLoading
)

export const selectAuthorizedFleetAccounts = createSelector(
  selectUserState,
  (state: State) => state.authorizedFleetAccounts
)

export const selectDisabledFleetAccounts = createSelector(
  selectUserState,
  (state: State) => state.disabledAuthorizedFleetAccounts
)

export const selectFleetAccountsDictionary = createSelector(
  selectAuthorizedFleetAccounts,
  selectDisabledFleetAccounts,
  (authorizedFleetAccounts, disabledFleetAccounts) =>
    authorizedFleetAccounts?.length
      ? createFleetAccountsDictionary([
          ...authorizedFleetAccounts,
          ...disabledFleetAccounts,
        ])
      : null
)

export const selectAuthorizedAndDisabledFleetAccounts = createSelector(
  selectUserState,
  (state: State) => [
    ...(state.authorizedFleetAccounts ?? []),
    ...(state.disabledAuthorizedFleetAccounts ?? []),
  ]
)

export const selectAuthorizedFleetCompanies = createSelector(
  selectUserState,
  (state: State) => state.authorizedFleetCompanies
)

export const selectSelectedFleetCompanyId = createSelector(
  selectUserState,
  (state: State) => state.selectedFleetCompanyId
)

export const selectSelectedFleetAccountId = createSelector(
  selectUserState,
  (state: State) => state.selectedFleetAccountId
)

export const selectAreAllSitesSelected = createSelector(
  selectSelectedFleetAccountId,
  (selectedFleetAccountId) => selectedFleetAccountId === 'ALL'
)

export const selectAuthorizedFleetCustomer = createSelector(
  selectUserState,
  (state: State) => state.fleetCustomer
)

export const selectedSites = createSelector(
  selectAuthorizedFleetAccounts,
  selectSelectedFleetCompanyId,
  (fleetAccounts, fleetCompanyId) =>
    fleetAccounts.filter((account) => account.fleetCompanyId === fleetCompanyId)
)

export const selectSelectedFleetAccount = createSelector(
  selectSelectedFleetAccountId,
  selectAuthorizedFleetAccounts,
  (selectedFleetAccountId, authorizedFleetAccounts) => {
    if (selectedFleetAccountId && (authorizedFleetAccounts || []).length) {
      return authorizedFleetAccounts.find(
        (afa) => afa._id === selectedFleetAccountId
      )
    }
    return null
  }
)

export const selectSelectedFleetCompany = createSelector(
  selectSelectedFleetCompanyId,
  selectAuthorizedFleetCompanies,
  (selectedFleetCompanyId, authorizedFleetCompanies) => {
    if (selectedFleetCompanyId && (authorizedFleetCompanies || []).length) {
      return authorizedFleetCompanies.find(
        (afc) => afc._id === selectedFleetCompanyId
      )
    }
    return null
  }
)

export const selectHasSubAccounts = createSelector(
  selectSelectedFleetCompany,
  (selectedFleetCompany) => (selectedFleetCompany?.subAccounts ?? []).length > 0
)

export const selectIsBillingPageVisible = createSelector(
  selectSelectedFleetAccount,
  selectSelectedFleetCompany,
  selectAuthorizedFleetAccounts,
  (selectedFleetAccount, selectedFleetCompany, authorizedFleetAccounts) => {
    if (selectedFleetAccount === null && selectedFleetCompany === null) {
      return null
    }

    const authorizedAccounts = authorizedFleetAccounts.filter(
      (fleetAccount) => fleetAccount.fleetCompanyId === selectedFleetCompany._id
    )

    return authorizedAccounts.every(
      (fleetAccount) => fleetAccount.paymentProcessor === 'NONE'
    )
  }
)

export const selectIsSitesPageVisible = createSelector(
  selectSelectedFleetAccount,
  selectSelectedFleetCompany,
  selectAuthorizedFleetAccounts,
  (selectedFleetAccount, selectedFleetCompany) => {
    if (selectedFleetAccount === null && selectedFleetCompany === null) {
      return null
    }

    return true
  }
)

export const selectFleetRoles = createSelector(
  selectUserState,
  (state: State) => state.fleetRoles
)

export const selectIsFuelConsultant = createSelector(
  selectUserState,
  (state: State) => state.isFuelConsultant
)

export const selectedFeatureFlags = createSelector(
  selectSelectedFleetCompany,
  selectIsFuelConsultant,
  (selectedFleetCompany, isFuelConsultant) => {
    if (selectedFleetCompany) {
      return {
        featureFlags: selectedFleetCompany.clientFeatureFlags,
        isFuelConsultant,
      }
    }
    return null
  }
)

export const selectPermissionsData = createSelector(
  selectFleetRoles,
  selectIsFuelConsultant,
  selectSelectedFleetCompanyId,
  (fleetRoles, isFuelConsultant, selectedFleetCompanyId) => {
    let fleetRole = (fleetRoles || []).find(
      (fr) => fr.attributes.fleetCompanyId === selectedFleetCompanyId
    )
    fleetRole = fleetRole || void 0
    return {
      fleetRole,
      isFuelConsultant,
    }
  }
)

export const selectRolesWithAuthorizedNames = createSelector(
  selectFleetRoles,
  selectAuthorizedFleetAccounts,
  (fleetRoles, fleetAccounts) => {
    if (fleetRoles.length === 0) {
      return []
    }

    const accountDictionary = fleetAccounts.reduce(
      (dictionary, fleetAccount) => {
        return { ...dictionary, [fleetAccount._id]: fleetAccount.displayName }
      },
      {}
    )

    return fleetRoles.map((role) => {
      const isFleetAccountRole = role.name
        .toLowerCase()
        .startsWith('fleet_account')
      const names = isFleetAccountRole
        ? role.attributes.fleetAccountIds.map((id) => accountDictionary[id])
        : ['All']

      return { ...role, names }
    })
  }
)

export const selectServiceLocations = createSelector(
  selectFleetRoles,
  selectIsFuelConsultant,
  selectAuthorizedFleetCompanies,
  selectAuthorizedFleetAccounts,
  (
    fleetRoles,
    isFuelConsultant,
    authorizedFleetCompanies,
    authorizedFleetAccounts
  ) => {
    if (
      authorizedFleetCompanies === null ||
      selectAuthorizedFleetAccounts === null
    ) {
      return null
    }

    let serviceLocations: ServiceLocation[] = []
    serviceLocations = serviceLocations.concat(
      authorizedFleetAccounts
        .map(({ _id, fleetCompanyId, displayName, paymentProcessor }) => ({
          fleetAccountId: _id,
          fleetCompanyId,
          displayName,
          paymentProcessor,
          isBillingDisabled:
            (fleetRoles.find(
              (role) =>
                role.attributes.fleetCompanyId === fleetCompanyId &&
                role.name === 'FLEET_SUB_ACCOUNT'
            ) ||
              !isFuelConsultant) &&
            (!['RMD_PRICING', 'RMD_BILLING'].every((featureFlag) =>
              get(
                authorizedFleetCompanies.find(
                  (fleetCompany) => fleetCompany._id === fleetCompanyId
                ),
                'clientFeatureFlags',
                []
              ).includes(featureFlag)
            ) ||
              !authorizedFleetAccounts
                .filter(
                  (fleetAccount) =>
                    fleetAccount.fleetCompanyId === fleetCompanyId
                )
                .every(
                  (fleetAccount) => fleetAccount.paymentProcessor === 'NONE'
                )),
        }))
        .sort((a, b) => a.displayName.localeCompare(b.displayName))
    )

    if (authorizedFleetAccounts.length === 1) {
      const [authorizedFleetAccount] = authorizedFleetAccounts
      const isFleetCompanyRole = fleetRoles.find(
        (role) =>
          role.attributes.fleetCompanyId ===
            authorizedFleetAccount.fleetCompanyId &&
          includesFleetCompanyFleetRole(role.name)
      )
      if (isFleetCompanyRole) {
        serviceLocations = authorizedFleetCompanies
          .filter(
            (company) => company._id === authorizedFleetAccount.fleetCompanyId
          )
          .map(({ _id, clientFeatureFlags }) => ({
            fleetAccountId: 'ALL',
            fleetCompanyId: _id,
            displayName: authorizedFleetAccount.displayName,
            paymentProcessor: authorizedFleetAccount.paymentProcessor,
            isBillingDisabled:
              (!isFuelConsultant &&
                !(
                  fleetRoles.find((role) =>
                    includesFleetCompanyFleetRole(role.name)
                  ) && (clientFeatureFlags || []).includes('RMD_BILLING')
                )) ||
              !authorizedFleetAccounts
                .filter((account) => account.fleetCompanyId === _id)
                .every((account) => account.paymentProcessor === 'NONE'),
          }))
      }
      return serviceLocations
    }

    const adminRoleIds = fleetRoles
      .filter((role) => includesFleetCompanyFleetRole(role.name))
      .map((role) => role.attributes.fleetCompanyId)

    const allSites = authorizedFleetCompanies
      .filter((afc) => adminRoleIds.includes(afc._id) || isFuelConsultant)
      .sort((a, b) => a.displayName.localeCompare(b.displayName))
      .map(({ _id, displayName, clientFeatureFlags }) => ({
        fleetAccountId: 'ALL',
        fleetCompanyId: _id,
        displayName: `All sites - ${displayName}`,
        paymentProcessor: null,
        isBillingDisabled:
          (!isFuelConsultant &&
            !(
              fleetRoles.find((role) =>
                includesFleetCompanyFleetRole(role.name)
              ) && (clientFeatureFlags || []).includes('RMD_BILLING')
            )) ||
          !authorizedFleetAccounts
            .filter((account) => account.fleetCompanyId === _id)
            .every((account) => account.paymentProcessor === 'NONE'),
      }))

    serviceLocations = [...allSites, ...serviceLocations]

    return serviceLocations
  }
)

export const selectSelectedServiceLocation = createSelector(
  selectServiceLocations,
  selectSelectedFleetAccountId,
  selectSelectedFleetCompanyId,
  (serviceLocations, selectedFleetAccountId, selectedFleetCompanyId) => {
    if (serviceLocations === null) {
      return null
    }

    const selectedServiceLocation = serviceLocations.find((serviceLocation) => {
      return (
        serviceLocation.fleetAccountId === selectedFleetAccountId &&
        serviceLocation.fleetCompanyId === selectedFleetCompanyId
      )
    })

    return selectedServiceLocation
  }
)

export const selectFleetCompanySubAccounts = createSelector(
  selectSelectedFleetCompany,
  (fleetCompany) => {
    if (fleetCompany === null) {
      return null
    }
    const fleetCompanySubAccounts: FleetCompany['subAccounts'] = (
      fleetCompany?.subAccounts ? [...fleetCompany.subAccounts] : []
    ).sort((s1, s2) =>
      s1.name.toLowerCase().localeCompare(s2.name.toLowerCase())
    )

    return fleetCompanySubAccounts
  }
)

export const selectSubAccounts = createSelector(
  selectFleetCompanySubAccounts,
  selectSelectedFleetAccount,
  selectSelectedFleetAccountId,
  (fleetCompanySubAccounts, fleetAccount, fleetAccountId) => {
    if (fleetCompanySubAccounts === null || fleetAccount === null) {
      return null
    }

    if (fleetAccountId === 'ALL') {
      return fleetCompanySubAccounts
    }

    const fleetAccountSubAccounts: { subAccountId: string }[] =
      fleetAccount?.subAccounts ?? []

    return fleetCompanySubAccounts.filter((s1) =>
      fleetAccountSubAccounts.some((s2) => s2.subAccountId === s1._id)
    )
  }
)

export const selectHasSubaccountRole = createSelector(
  selectPermissionsData,
  (permissionsData) => permissionsData.fleetRole?.name === 'FLEET_SUB_ACCOUNT'
)

export const selectAccountSelectOptions = createSelector(
  selectHasSubaccountRole,
  selectSelectedFleetCompanyId,
  selectSelectedFleetAccountId,
  selectSelectedFleetCompany,
  selectAuthorizedFleetAccounts,
  (
    hasSubaccountRole,
    selectedFleetCompanyId,
    selectedFleetAccountId,
    selectedFleetCompany,
    authorizedFleetAccounts
  ) => {
    if (!authorizedFleetAccounts) {
      return { fleetAccountOptions: [], subAccountOptions: [] }
    }

    const { fleetAccountOptions, subAccountOptions } = getAccountSelectOptions(
      hasSubaccountRole,
      selectedFleetCompanyId,
      selectedFleetAccountId,
      authorizedFleetAccounts,
      selectedFleetCompany?.subAccounts || []
    )
    return { fleetAccountOptions, subAccountOptions }
  }
)

export const selectEmailNotificationPreferences = createSelector(
  selectUserState,
  (state: State) => state?.fleetCustomer?.emailNotificationPreferences || []
)

export const selectSmsNotificationPreferences = createSelector(
  selectUserState,
  (state: State) => state?.fleetCustomer?.smsNotificationPreferences || []
)

export const selectIsFleetAccountAdmin = createSelector(
  selectPermissionsData,
  ({ fleetRole, isFuelConsultant }) =>
    isFuelConsultant || includesFleetCompanyFleetRole(fleetRole?.name ?? '')
)

export const selectOnboardingFleetAccounts = createSelector(
  selectAuthorizedFleetAccounts,
  (authorizedFleetAccounts) =>
    (authorizedFleetAccounts || []).filter(
      (fleetAccount) => fleetAccount.status === 'ONBOARDING'
    )
)
