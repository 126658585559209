import { Product } from '@boosterfuels/types/types-ts'
import { ProductsDictionary } from '../typings'

export function createProductsDictionary(
  products: Product[]
): ProductsDictionary {
  return (products ?? []).reduce<ProductsDictionary>(
    (productsDictionary, product) => {
      productsDictionary[product._id] = product
      return productsDictionary
    },
    {}
  )
}
