import energyTypes from '@boosterfuels/types/util/energyTypes'
import energyTypeGroups from '@boosterfuels/types/util/energyTypeGroups'

export const ENERGY_TYPES: string[] = energyTypes
export const ENERGY_TYPE_GROUPS: string[] = energyTypeGroups

export interface ReducedAmountPerEnergyTypeGroups {
  [energyTypeGroup: string]: number
  TOTAL?: number
}
