'use strict';
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = [
    'REGULAR',
    'PREMIUM',
    'DIESEL',
    'RENEWABLE_DIESEL',
    'DYED_DIESEL',
    'ETHANOL',
];
