import { ReducedAmountPerEnergyTypeGroups } from '../../_core/models/energy-types.models'
import { AmountPerEnergyTypeGroup } from '@boosterfuels/types/types-ts/embedded'

export function reduceAmountPerEnergyTypeGroups(
  items: AmountPerEnergyTypeGroup[]
): ReducedAmountPerEnergyTypeGroups {
  return items.reduce((acc, pair) => {
    acc[pair.energyTypeGroup] = pair.amount
    return acc
  }, {})
}
